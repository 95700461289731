<style lang="scss" scoped>
.dialog-header {
  background-color: #194E76;

  .heading_text {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
  }

  .close-btn {
    position: absolute;
    top: 12px;
    right: 10px;
  }
}

.comment-div {
  height: 400px;
  padding: 20px 30px 10px 30px;
  overflow-y: scroll;
}

.other-comment {
  background: #FFFFFF;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
}

.my-comment {
  background: #FFECDC;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
}

.text-1 {
  font-size: 10px;
  line-height: 12px;
  color: #6F6F6F;
}

.text-2 {
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}


</style>

<template>
  <v-dialog v-model="dialog.flag" width="600px">
    <v-card>
      <div>

        <v-card-title class="pl-7 dialog-header">
          <span class="heading_text">{{ $lang.INTERNAL_COMMENTS }}</span>
          <span class="close-btn">
              <v-icon class="float-right" color="white" @click="closeDialog">mdi-close</v-icon>
            </span>
        </v-card-title>
        <v-card-text class="comment-div">
          <div v-for="item in comment_list" :key="item.id" class="mb-5">
            <v-row v-if="!item.is_my_comment" justify="start">
              <v-col class="other-comment " md="6">
                <p class="text-1"> {{ item.name }}</p>
                <p class="text-2 pt-2">{{ item.comment }}</p>
                <p class="text-1 text-right pt-2">{{ item.created }}</p>
              </v-col>
            </v-row>
            <v-row class="pt-4" justify="end" v-else>
              <v-col class="my-comment" md="6">
                <p class="text-1"> {{ item.name }}</p>
                <p class="text-2 pt-2">{{ item.comment }}</p>
                <p class="text-1 text-right pt-2">{{ item.created }}</p>
              </v-col>
            </v-row>
          </div>
        </v-card-text>

        <v-form ref="add_comment_form" lazy-validation @submit.prevent="addComment()">
          <v-card-actions class="pb-5 px-5">
            <v-row class="pt-4">
              <v-col md="3">
                <v-menu
                    v-model="date_menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    min-width="290px"
                    offset-y
                    transition="scale-transition"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        v-model="display_date"
                        v-on="on"
                        :label="$lang.FOLLOW_UP_DATE"
                        :placeholder="$lang.FOLLOW_UP_DATE"
                        class="filter"
                        dense
                        readonly
                    >
                      <template slot="append">
                        <!--                    <v-icon v-if="dialog.follow_up_date" @click="clearDateFilter()">mdi-close</v-icon>-->
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                      v-model="dialog.follow_up_date"
                      :min="today"
                  >
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="date_menu = false">Cancel</v-btn>
                    <v-btn color="primary" text @click="formateDisplayDate">OK</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col class="pr-5" cols="10" md="9">
                <v-text-field
                    v-model="comment"
                    dense
                    hide-details
                    label="Internal Message"
                    outlined
                    rounded
                    single-line
                >
                  <template slot="append-outer">
                    <v-btn :disabled="comment||dialog.follow_up_date?false:true" fab outlined small
                           style="margin-top: -7px;margin-right: -20px" text
                           type="submit" class="pl-1">
                      <v-icon color="secondary_2" @click="addComment">mdi-send</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>

          </v-card-actions>
        </v-form>
      </div>
    </v-card>

  </v-dialog>
</template>
<script>
export default {
  props: ['dialog'],
  components: {},
  data: () => ({
    comment: '',
    display_date: null,
    date_menu: false,
    today: new Date().toISOString().substr(0, 10),
    comment_list: [],
  }),
  created() {
  },
  methods: {
    closeDialog() {
      this.dialog.flag = false
    },

    getCommentList(installment_id, loan_id) {
      const self = this;

      self.toggle_loading()

      let loan__id = loan_id === undefined ? null : loan_id;

      let params = {
        installment_id: installment_id,
        loan_id: loan__id,
      };
      const successHandler = (response) => {
        if (response.data.success) {
          self.comment_list = response.data.comment_list;
        }
      };
      const finallyHandler = () => {
      };

      self.request_GET(self, self.$urls.INTERNAL_COMMENTS_LIST, params, successHandler, null,
          null, finallyHandler);
    },
    /* function to fetch data from api */
    addComment(id) {
      const self = this;
      if (!self.comment || !self.dialog.follow_up_date) {
        if (!self.dialog.follow_up_date) {
          // console.log("Follow-up date is requried");
          self.showSnakeBar("error", "Follow-up date is requried");
        } else if (!self.comment) {
          self.showSnakeBar("error", "Comment is requried");
        } else {
          self.showSnakeBar(
            "error",
            "Both Follow-up date and Comment is requried"
          );
        }
        return false;
      } else {
        var form = new FormData();
        form.append("installment_id", self.dialog.installment_id);
        form.append("loan_id", self.dialog.loan_id);
        form.append("comment", self.comment);
        if (self.dialog.follow_up_date)
          form.append("follow_up_date", self.dialog.follow_up_date);

        self.comment = null;
        const successHandler = (response) => {
          if (response.data.success) {
            self.getCommentList(
              self.dialog.installment_id,
              self.dialog.loan_id
            );
          }
        };
        const finallyHandler = () => {};
        self.request_POST(
          self,
          self.$urls.ADD_INTERNAL_COMMENTS,
          form,
          successHandler,
          null,
          null,
          finallyHandler
        );
      }
    },
    /* function to format the date */
    formateDisplayDate() {
      this.display_date = this.display_date_formate(this.dialog.follow_up_date)
      this.date_menu = false;
    },
  },
}
</script>
